export const CURRENCY_SYMBOLS = {
    BGN: 'лв',
    CLP: 'CLP$',
    CNY: '¥',
    COP: 'COL$',
    CZK: 'Kč',
    DKK: 'kr.',
    EUR: '€',
    HUF: 'Ft',
    IDR: 'Rp',
    INR: '₹',
    JPY: '¥',
    KRW: '₩',
    KZT: '₸',
    MXN: 'Mex$',
    MYR: 'RM',
    NOK: 'kr',
    PEN: 'S/.',
    PLN: 'zł',
    RON: 'lei',
    RSD: 'РСД',
    SEK: 'kr',
    THB: '฿',
    UAH: '₴',
    USD: '$',
    ARS: 'ARS',
    BZD: 'BZ$',
    CRC: '₡',
    UYU: '$U',
    GTQ: 'Q',
    HNL: 'L',
    NIO: 'C$',
    DOP: 'RD$',
    BOB: 'Bs.',
    PYG: '₲',
    PAB: 'B/.'
};

export const CURRENCIES_TO_HIDE_CENTS = ['ARS', 'COP', 'GTQ', 'CLP'];
