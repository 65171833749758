import { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import isAuthorDomain from './utils/is-author-domain';
import lastVisitedWorld from './utils/last-visited-world';
import prerenderMeta from './utils/prerender-meta';
import initInterceptors from './utils/init-interceptors';
import setStorage from './utils/storage';
import canUseWebP from './utils/image-formats';
import errorTracker from './utils/error-tracker';
import globalDebug from './utils/debugger';
import isProduction from './utils/is-production';
import isUITest from './utils/is-ui-test';
import isPrerender from './utils/is-prerender';
import usercentrics from './services/usercentrics';
import isMobileAppWebview from './utils/is-mobile-app-webview';

// Disable showing consoles (log, warn, info, error) on the production
globalDebug(!isProduction());

errorTracker.launch();
setStorage();
initInterceptors();
window.canUseWebP = canUseWebP();
window.enhancedConversionInfo = {}; // additional info for enhanced conversions in Adwords

const SelectionScreen = lazy(() => import('./routes/selection-screen-new/selection-screen'));
const App = lazy(() => import('./App'));

function Entry() {
    const htmlTag = document.getElementsByTagName('html')[0];
    const lastWorld = lastVisitedWorld.get();
    const authorDomain = isAuthorDomain();

    htmlTag.setAttribute('lang', 'en');

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.isPageLoaded && window.pendingRequests && !Object.keys(window.pendingRequests).length) {
                clearInterval(interval);
                setTimeout(() => {
                    prerenderMeta.set(200);
                }, 10000);
            }
        }, 1000);

        const href = decodeURIComponent(window.location.href);

        if (href.indexOf('query=?utm_source') > -1) {
            window.location.href = href.replace('query=?', 'query=&');
        }

        // Init Usercentrics service
        // Skip it for tests and pre-rendering
        if (!isUITest() && !isPrerender() && !isMobileAppWebview()) {
            usercentrics.init();
        }
    }, []);

    // const [detectedCode] = useState(false);

    // useEffect(() => {
    //     const browserLang = (navigator.language || '').slice(0, 2).toLowerCase();
    //     const lastWorld = lastVisitedWorld.get();
    //
    //     if (availableLanguages.includes(lastWorld)) {
    //         setDetectedCode(lastWorld);
    //     } else if (availableLanguages.includes(browserLang)) {
    //         setDetectedCode(browserLang);
    //     } else {
    //         countryByIP.get().then(info => {
    //             const countryCode = (info.code || '').toLowerCase();
    //
    //             if (availableLanguages.includes(countryCode)) {
    //                 setDetectedCode(countryCode)
    //             } else {
    //                 setDetectedCode(fallbackLang);
    //             }
    //         });
    //     }
    // }, []);

    return (
        //@ts-ignore
        <BrowserRouter>
            <Suspense fallback={<div />}>
                {Boolean(authorDomain || window.location.pathname !== '/') && (
                    <Switch>
                        <App />
                    </Switch>
                )}
                {Boolean(!authorDomain && window.location.pathname === '/') && (
                    <Switch>
                        {lastWorld && <Redirect exact from='/' to={lastWorld} />}
                        {lastWorld && <App />}
                        {!lastWorld && <SelectionScreen />}
                    </Switch>
                )}
            </Suspense>
        </BrowserRouter>
    );
}

export default Entry;
